.ResultBox {
  .ResultHeader {
    padding: 10px;
    // .ResultButton {
    //   display: inline-block;
    //   padding: 5px 10px;
    //   font-size: 16px;
    //   text-align: center;
    //   cursor: pointer;
    //   color: #fff;
    //   background-color: #8661ff;
    //   border: 2px solid #8661ff;
    //   border-radius: 5px;
    //   transition: all 0.3s ease;

    //   &:hover {
    //     background-color: #fff;
    //     color: #8661ff;
    //   }
    // }
  }
  .ResultText {
    padding: 0 10px 0 10px;
    border: 1px solid #8661ff;
    height: 400px;
    overflow-y: auto;
    width: 100%;
    white-space: pre-wrap;
    font-family: Monaco;
    text-align: left;
    background-color: #fafafa;
    cursor: default;
  }
}

.ResultButton {
  font-size: 16px;
  font-weight: 500;
  position: relative;
  border: 2px solid hsla(0, 2%, 91%, 0.8);
  border-radius: 0;
  color: #fff;
  opacity: 1;
  padding: 8px 30px;
  background: linear-gradient(
    200deg,
    rgba(255, 0, 255, 0.7) 20%,
    rgba(0, 255, 255, 0.6) 75%
  );
  cursor: pointer;
  overflow: hidden;
  z-index: 1;
}

.ResultButton:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    20deg,
    rgba(255, 0, 255, 0.7) 20%,
    rgba(0, 255, 255, 0.6) 75%
  );
  // background: #8661ff;
  opacity: 1;
  transition: opacity 0.5s ease;
  z-index: -1;
}

.ResultButton:hover {
  &:before {
    opacity: 0;
  }
}
