.Import {
  // width: 600px;
  // margin: 0 auto;
}

.jsoneditor {
  border: 1px solid #8661ff;
}
.jsoneditor-menu {
  background-color: #8661ff;
  button {
    color: white;
    &:hover {
      color: black;
    }
  }
  // .jsoneditor-modal .pico-modal-header {
  //   background: #8661ff;
  // }
}
