.Editor {
  display: flex;
  justify-content: space-between;
}

.EditorEdit {
  width: 100%;
  margin-right: 5px;
}

.AceEditor {
  border: 1px solid #8661ff;
  // margin: 0 auto;
  height: 400px;
  width: 100%;
  &-error {
    border-color: red;
  }
  .ace_gutter {
    z-index: 0;
  }
}
.EditorTextarea {
  width: 100%;
  margin-left: 5px;
}
.EditorPreview {
  font-size: 14px;
  line-height: 19px;
  text-align: left;
  height: 400px;
  background-color: #fafafa;
  cursor: default;
  padding: 0 10px 0 10px;
  border: 1px solid #8661ff;
  overflow-y: auto;
  white-space: pre-wrap;
  font-family: Monaco;
}
